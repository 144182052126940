<template>
    <b-card title="Card Title" no-body>
        <b-card-header header-tag="nav">
            <b-nav id="#nav" align="center" card-header tabs>
                <b-nav-item v-bind:active="tab === 1" v-on:click="tab = 1"
                            :to="{name: `GecmisIslemler`}">Geçmiş İşlemler</b-nav-item>
                <b-nav-item v-bind:active="tab === 2" v-on:click="tab = 2"
                            :to="{name: `Izinler`}">İzinler</b-nav-item>
            </b-nav>
        </b-card-header>

        <b-card-body>
            <router-view></router-view>
        </b-card-body>
    </b-card>
</template>

<script>
    export default {
        name: "gonullu",
        data() {
            return {
                tab: 1
            }
        }
    }
</script>

<style scoped lang="scss">

</style>
